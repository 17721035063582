  
  section {
    gap: 60px;
    display: flex;
    align-items: center;
  }

  /* .slides-wrap:hover section {
    animation-play-state: paused !important;
  } */
  
  @keyframes swipe-right {
    0% {
      transform: translate(-100%);
    }
  
    100% {
      transform: translate(0);
    }
  }

  @keyframes swipe-left {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }